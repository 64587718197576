* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Akaya Telivigala', cursive;
  text-transform: capitalize;
  font-size: 1rem;
  margin-top: 1em;
  background-size: cover;
  background-color: rgb(143, 202, 241);
  color: rgb(4, 4, 94);
}

header {
  text-align: center;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

button {
  margin-top: 10px;
  width: 100px;
  height: 40px;
  font-size: large;
}

header img {
  width: 2rem;
  height: 2rem;
  margin-left: 1em;
}

.container {
  color: rgb(9, 9, 109);
  width: 37rem;
  /* margin: 1em; */
  display: flex;
  justify-content: space-around;
}

.input {
  border-radius: 2rem 2rem;
  padding: 0.5em;
  margin: 1em;
  background-color: rgb(42, 42, 180);
}

#input-text {
  text-align: center;
  color: #721f04;
}

.input-text-box {
  display: flex;
  justify-content: center;
}

.add {
  color: white;
  background-color: rgb(60, 60, 238);
  border: 2px solid white;
  cursor: pointer;
  width: 5em;
  height: 3em;
  box-shadow: 5px 10px 20px rgb(112, 112, 255);
  opacity: 0.8;
}

.add:hover {
  opacity: 1;
  border: 5px inset rgb(224, 216, 216);
}

#input-text {
  width: 75%;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
}

.list-container {
  margin: 1em;
  background-color: rgb(79, 79, 255);
  border-radius: 2rem 2rem;
  padding: 1em;
  width: 40vh;
}

.card-header {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 0.8rem;
  margin: 1em;
  box-shadow: 5px 10px 20px #5cb7e0 inset;
  border-radius: 1em;
  color: rgb(7, 7, 109);
  text-shadow: 2px 0 2px white;
  font-size: 1.3rem;
  background-color: white;
}

#deleteAll {
  color: white;
  cursor: pointer;
  padding: 0.3em;
  background-color: blue;
  border: 2px solid #0868d6;
  border-radius: 7px;
  box-shadow: 5px 10px 20px #5cb7e0 inset;
  outline: 3px solid white;
  opacity: 0.7;
  transform: 0.5s;
}

#deleteAll:hover {
  opacity: 1;
  width: 6rem;
}

.fas {
  margin-left: 0.5em;
}

ul li{
  background-color: white;
  padding: 1em;
  margin: 1em;
  border: 1px solid #0868d6;
  border-radius: 10px;
  box-shadow: 5px 10px 20px #3a8deb inset;
  overflow-wrap: break-word;
  list-style-image: url(./img/sticky.png);
  list-style-position: inside;
  font-size: 1.2rem;
}

.fixed {
  text-decoration: line-through;
  padding: 1em;
  margin: 1em;
  border: 1px solid #0868d6;
  box-shadow: 5px 10px 20px #3a8deb inset;
  overflow-wrap: break-word;
  opacity: 0.8;
}

.date{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    font-size: 1.5rem;

}
.date span{
    width: 7rem;
}

@media screen and (max-width: 470px) {
  .container {
      width: 95%;
  }
}
